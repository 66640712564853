body {
  background: #FFF;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color:#242424;
  padding-right:0 !important;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased !important;
  overflow-x:hidden;
}
h3, h4 {
  font-weight: 600;
  line-height: 1.4;
  font-family: 'Quicksand', sans-serif;
}
h1, h2 {
  line-height: 1.5;
  font-weight: 600;
  font-family: 'Quicksand', sans-serif;
}
h5, h6 {
  line-height: 1.4;
  font-family: 'Quicksand', sans-serif;
}
p {
  font-family: 'Poppins', sans-serif;
  line-height: 30px;
  font-size: 14px;
  color: #777;
}
.xcs__indicator.xcs__dropdown-indicator {
  padding: 4px;
  background-color: transparent;
}

.x-rs-custom-option {
  transition: background 60ms;
}
.x-rs-custom-option:hover {
  transition-delay: 60ms;
  background: #deebff;
}
.x-rs-custom-option.xcs__option--is-focused {
  background: none;
}
.x-rs-custom-option.xcs__option--is-selected {
  background: #2684FF;
}
.react-virtualized-menu-placeholder {
  margin-top: 12px;
  color: #9a9a9a;
  text-align: center;
}
.react-virtualized-list-wrapper li {
  list-style: none;
}
/* .xcs__menu-portal, */
.x-rs-custom-option {
  background-color: #fff !important;
}

.x-appl-field {
  width: 50%;
  padding: 10px;
}

.x-exp-bool.chakra-form-control {
  max-width: 180px;
}
.ml-2 {
  margin-left: 4px;
}
.x-no-lang .x-lang {
  display: none;
}
.x-job-detail.x-cl-11541 .xcs-job-details-summary {
  display: none;
}

@media only screen and (max-width: 600px) {
  .x-hm-con,
  .x-lay-con {
    padding: 0px !important;
  }
  .xcs-jlist {
    grid-gap: 8px !important;
  }
  .xjd-wrap {
    max-height: initial !important;
    padding: 8px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .x-jb-filters {
    min-width: auto !important;
    flex-wrap: wrap !important;
  }
  .xcs-jtorkw-input,
  .xcs-loc-dropdown {
    flex: auto !important;
    width: 100% !important;
  }
  .x-jf-btng {
    margin-top: 0 !important;
  }
  .x-jb-filters-inner {
    flex-wrap: wrap !important;
  }
  .x-jf-collapse {
    width: 100% !important;
  }
  .x-jf-btng-wrap {
    margin-top: 4px !important;
  }
  .x-appl-field {
    width: 100%;
  }
  .x-jb-logo-wrap {
    display: none !important;
  }
}